import React from "react"
import { Helmet } from "react-helmet";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import IndexContent from "./index_content/index_content";

// import "@popperjs/core/dist/umd/popper.min"
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.min"

const Index: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <Helmet
        // meta={[
        //   { "name": "viewport", "charset": "utf-8", "content": "width=device-width, initial-scale=1" }
        // ]}

        // link={[
        //   {
        //     "href": "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css", "rel": "stylesheet",
        //     "integrity": "sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC", "crossorigin": "anonymous"
        //   },
        //   { "rel": "preconnect", "href": "https://fonts.googleapis.com" },
        //   { "rel": "preconnect", "href": "https://fonts.gstatic.com", "crossorigin": "anonymous" },
        //   { "rel": "preconnect", "href": "https://fonts.googleapis.com" },
        //   { "rel": "preconnect", "href": "https://fonts.gstatic.com", "crossorigin": "anonymous" },
        //   {
        //     "href":
        //       "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap", "rel": "stylesheet"
        //   },
        //   { "rel": "preconnect", "href": "https://fonts.googleapis.com" },
        //   { "rel": "preconnect", "href": "https://fonts.gstatic.com", "crossorigin": "anonymous" },
        //   { "rel": "preconnect", "href": "https://fonts.googleapis.com" },
        //   { "rel": "preconnect", "href": "https://fonts.gstatic.com", "crossorigin": "anonymous" },
        //   {
        //     "href":
        //       "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap", "rel": "stylesheet"
        //   },
        //   { "rel": "stylesheet", "href": "style.css", type: "text/css" },
        //   { "rel": "shortcut icon", "type": "image/jpg", "href": "./assets/images/logo-transparent.png" }
        // ]}
        // script={[
        //   {
        //     "src": "https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js",
        //     "type": "text/javascript",
        //     "integrity": "sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p",
        //     "crossorigin": "anonymous"
        //   },
        //   {
        //     "src": "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js",
        //     "type": "text/javascript",
        //     "integrity": "sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF",
        //     "crossOrigin": "anonymous"
        //   }
        // ]}
        defaultTitle="Safetrans"
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" /> */}
        < link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />

        {/* <!-- CUSTOM FONTS --> */}
        < link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap" rel="stylesheet" />

        {/* <!-- CUSTOM STYLING --> */}
        < link rel="stylesheet" href="styling.css" />
        <title>{intl.formatMessage({ id: 'metadata_title' })}</title>
        <link rel="shortcut icon" type="image/jpg" href="./assets/images/logo-transparent.png" />

      </Helmet>
      <IndexContent />
    </>
  );
}

export default Index;